<template>
  <!-- Leaderboard Section -->
  <section
    id="leaderboard"
    class="h-auto leaderboard-bg mt-24 md:mt-[174px] scroll-mt-20"
  >
    <div
      class="w-full flex justify-center items-center px-4 md:px-8 lg:pt-0"
      id="leaderboard"
    >
      <div
        class="w-full flex flex-col justify-center items-center my-8 md:my-10 md:p-8 rounded-3xl"
      >
        <div
          class="text-white text-2xl md:text-[48px] text-center leading-[48px]"
          style="font-family: Montserrat, sans-serif"
        >
          $13,000 Weekly STAKE
          <span
            class="text-transparent bg-clip-text bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] font-[900]"
            >LEADERBOARD</span
          >
        </div>
        <div
          class="flex flex-col justify-center items-center my-10 lg:mt-10 lg:mb-5"
        >
          <div class="bg-transparent p-1 rounded-full">
            <div
              v-motion-slide-visible-bottom
              class="bg-transparent"
              style="font-family: Oxanium"
            >
              <div
                v-if="timer && timer != 'Leaderboard ended'"
                class="flex flex-row gap-3"
              >
                <div
                  class="bg-gradient-to-b from-[#F42F09] to-[#FF812E] h-[70px] w-[70px]"
                >
                  <h4
                    class="text-white text-[36px] leading-[46px] font-bold text-center"
                  >
                    {{
                      timer
                        .split(" ")[0]
                        .substring(0, timer.split(" ")[0].length - 1)
                    }}
                  </h4>
                  <h6
                    class="text-sm leading-6 font-bold text-center text-white"
                  >
                    DAYS
                  </h6>
                </div>
                <div
                  class="bg-gradient-to-b from-[#F42F09] to-[#FF812E] h-[70px] w-[70px]"
                >
                  <h4
                    class="text-white text-[36px] leading-[46px] font-bold text-center"
                  >
                    {{
                      timer
                        .split(" ")[1]
                        .substring(0, timer.split(" ")[1].length - 1)
                    }}
                  </h4>
                  <h6
                    class="text-sm leading-6 font-bold text-center text-white"
                  >
                    HRS
                  </h6>
                </div>
                <div
                  class="bg-gradient-to-b from-[#F42F09] to-[#FF812E] h-[70px] w-[70px]"
                >
                  <h4
                    class="text-white text-[36px] leading-[46px] font-bold text-center"
                  >
                    {{
                      timer
                        .split(" ")[2]
                        .substring(0, timer.split(" ")[2].length - 1)
                    }}
                  </h4>
                  <h6
                    class="text-sm leading-6 font-bold text-center text-white"
                  >
                    MIN
                  </h6>
                </div>
                <div
                  class="bg-gradient-to-b from-[#F42F09] to-[#FF812E] h-[70px] w-[70px]"
                >
                  <h4
                    class="text-white text-[36px] leading-[46px] font-bold text-center"
                  >
                    {{
                      timer
                        .split(" ")[3]
                        .substring(0, timer.split(" ")[3].length - 1)
                    }}
                  </h4>
                  <h6
                    class="text-sm leading-6 font-bold text-center text-white"
                  >
                    SEC
                  </h6>
                </div>
              </div>
              <div
                v-if="timer && timer == 'Leaderboard ended'"
                class="text-white text-2xl md:text-[36px] md:leading-[48px] font-bold py-2"
              >
                Leaderboard Ended
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="loading == true"
          class="text-center text-white text-xl md:text-4xl mt-10 font-bold"
          style="font-family: Secular One, sans-serif"
        >
          Loading Leaderboard...
        </div>
        <div v-if="loading == false" class="w-full relative">
          <div
            class="flex flex-row justify-center items-center gap-[34px] lg:gap-24 mt-32"
          >
            <div
              v-motion-pop-visible
              class="flex flex-col items-center mt-28 lg:mt-[88px]"
            >
              <div
                class="p-2.5 rounded-[10px] w-[150px] lg:w-[300px] bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B]"
              >
                <div
                  class="flex flex-col justify-center items-center pt-2 lg:px-6 lg:py-4 rounded-3xl relative"
                >
                  <img
                    src="../assets/avatar-image-plain.png"
                    class="absolute top-0 translate-x-4 -mt-[52px] lg:-mt-[100px] -ml-10 h-[80px] w-auto lg:h-[150px]"
                    alt="2nd-banner"
                  />
                  <!-- <img
                    src="../assets/crown-silver.png"
                    class="absolute top-0 translate-x-4 -mt-[138px] -ml-10"
                    alt="1st-banner"
                  /> -->
                  <div
                    class="flex flex-col justify-center items-center mt-5 lg:mt-8 rounded-[10px]"
                  >
                    <p
                      class="lg:text-[25px] lg:leading-9 text-xs bg-clip-text bg-gradient-to-r text-transparent from-[#F42F09] via-[#E69B6A] to-[#FF812E] overflow-hidden whitespace-nowrap text-ellipsis max-w-[130px] lg:max-w-[264px]"
                      style="font-family: Russo One, system-ui"
                    >
                      {{
                        top3.length > 0 ? maskString(top3[1]?.user_name) : ""
                      }}
                    </p>
                    <p
                      class="text-white text-lg lg:text-2xl uppercase mt-1 lg:mt-9 overflow-hidden whitespace-nowrap text-ellipsis max-w-[130px] lg:max-w-[264px]"
                      style="font-family: Montserrat, sans-serif"
                    >
                    <span class="font-thin text-white">{{ top3[1]?.wagered[0] }}</span>{{ top3.length > 0 ? top3[1]?.wagered.substring(1) : "" }}
                    </p>
                    <p
                      class="bg-clip-text bg-gradient-to-r text-transparent from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-xs lg:text-sm uppercase"
                      style="font-family: Lato, sans-serif"
                    >
                      Wagered:
                    </p>
                    <div
                      class="mt-1 lg:mt-4 skew-x-[-20deg] relative px-[26px] py-1 lg:py-[21px] lg:px-16 bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E]"
                    >
                      <p
                        class="skew-x-[20deg] text-white font-extrabold text-lg md:text-2xl"
                        style="font-family: Lato, sans-serif"
                      >
                        {{ getRewardValue(1) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-motion-pop-visible
              class="flex flex-col items-center lg:-mt-40 w-[150px] absolute top-2 lg:relative"
            >
              <div
                class="p-2.5 rounded-[10px] w-[150px] lg:w-[300px] bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B]"
              >
                <div
                  class="flex flex-col justify-center items-center pt-2 lg:px-6 lg:py-4 rounded-3xl relative"
                >
                  <img
                    src="../assets/avatar-image-plain.png"
                    class="absolute top-0 translate-x-4 -mt-[52px] lg:-mt-[100px] -ml-10 h-[80px] w-auto lg:h-[150px]"
                    alt="1st-banner"
                  />
                  <!-- <img
                    src="../assets/crown-gold.png"
                    class="absolute top-0 translate-x-4 -mt-[92px] lg:-mt-[138px] -ml-10"
                    alt="1st-banner"
                  /> -->
                  <div
                    class="flex flex-col justify-center items-center mt-5 lg:mt-8 rounded-[10px]"
                  >
                    <p
                      class="lg:text-[25px] lg:leading-9 text-xs bg-clip-text bg-gradient-to-r text-transparent from-[#F42F09] via-[#E69B6A] to-[#FF812E] overflow-hidden whitespace-nowrap text-ellipsis max-w-[130px] lg:max-w-[264px]"
                      style="font-family: Russo One, system-ui"
                    >
                      {{
                        top3.length > 0 ? maskString(top3[0]?.user_name) : ""
                      }}
                    </p>
                    <p
                      class="text-white text-lg lg:text-2xl uppercase mt-1 lg:mt-9 overflow-hidden whitespace-nowrap text-ellipsis max-w-[130px] lg:max-w-[264px]"
                      style="font-family: Montserrat"
                    >
                    <span class="font-thin text-white">{{ top3[0]?.wagered[0] }}</span>{{ top3.length > 0 ? top3[0]?.wagered.substring(1) : "" }}
                    </p>
                    <p
                      class="bg-clip-text bg-gradient-to-r text-transparent from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-xs lg:text-sm uppercase"
                      style="font-family: Lato, sans-serif"
                    >
                      Wagered:
                    </p>
                    <div
                      class="mt-1 lg:mt-4 skew-x-[-20deg] relative px-[26px] py-1 lg:py-[21px] lg:px-16 bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E]"
                    >
                      <p
                        class="skew-x-[20deg] text-white font-extrabold text-lg md:text-2xl"
                        style="font-family: Lato, sans-serif"
                      >
                        {{ getRewardValue(0) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-motion-pop-visible
              class="flex flex-col items-center mt-28 lg:mt-[88px]"
            >
              <div
                class="p-2.5 rounded-[10px] w-[150px] lg:w-[300px] bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B]"
              >
                <div
                  class="flex flex-col justify-center items-center pt-2 lg:px-6 lg:py-4 rounded-3xl relative"
                >
                  <img
                    src="../assets/avatar-image-plain.png"
                    class="absolute top-0 translate-x-4 -mt-[52px] lg:-mt-[100px] -ml-10 h-[80px] w-auto lg:h-[150px]"
                    alt="3rd-banner"
                  />
                  <!-- <img
                    src="../assets/crown-bronze.png"
                    class="absolute top-0 translate-x-4 -mt-[138px] -ml-10"
                    alt="1st-banner"
                  /> -->
                  <div
                    class="flex flex-col justify-center items-center mt-5 lg:mt-8 rounded-[10px]"
                  >
                    <p
                      class="lg:text-[25px] lg:leading-9 text-xs bg-clip-text bg-gradient-to-r text-transparent from-[#F42F09] via-[#E69B6A] to-[#FF812E] overflow-hidden whitespace-nowrap text-ellipsis max-w-[130px] lg:max-w-[264px]"
                      style="font-family: Russo One, system-ui"
                    >
                      {{
                        top3.length > 0 ? maskString(top3[2]?.user_name) : ""
                      }}
                    </p>
                    <p
                      class="text-white text-lg lg:text-2xl uppercase mt-1 lg:mt-9 overflow-hidden whitespace-nowrap text-ellipsis max-w-[130px] lg:max-w-[264px]"
                      style="font-family: Montserrat, sans-serif"
                    >
                      <span class="font-thin text-white">{{ top3[2]?.wagered[0] }}</span>{{ top3.length > 0 ? top3[2]?.wagered.substring(1) : "" }}
                    </p>
                    <p
                      class="bg-clip-text bg-gradient-to-r text-transparent from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-xs lg:text-sm uppercase"
                      style="font-family: Lato, sans-serif"
                    >
                      Wagered:
                    </p>
                    <div
                      class="mt-1 lg:mt-4 skew-x-[-20deg] relative px-[26px] py-1 lg:py-[21px] lg:px-16 bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E]"
                    >
                      <p
                        class="skew-x-[20deg] text-white font-extrabold text-lg md:text-2xl"
                        style="font-family: Lato, sans-serif"
                      >
                        {{ getRewardValue(2) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center mt-8 lg:mt-[91px] mb:mb-24">
            <table
              class="w-full lg:w-auto border-separate border-spacing-y-6 bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B] rounded-[20px]"
            >
              <thead
                v-motion-slide-visible-bottom
                class="bg-transparent"
                style="font-family: Montserrat, sans-serif"
              >
                <tr>
                  <th
                    class="text-right pl-4 pr-2 md:px-5 lg:px-[45px] xl:pr-12 xl:pl-24 py-2 md:py-4 text-white text-[18px] leading-6 font-bold rounded-l"
                  >
                    RANK
                  </th>
                  <th
                    class="px-1 md:px-5 lg:px-[45px] xl:px-2 py-2 md:py-4 text-white text-[18px] leading-6 font-bold text-center"
                  >
                    USER
                  </th>
                  <th
                    class="px-1 md:px-5 lg:px-[45px] xl:px-2 py-2 md:py-4 text-white text-[18px] leading-6 font-bold text-center"
                  >
                    WAGERED
                  </th>
                  <th
                    class="pr-4 pl-1 text-left md:px-5 lg:px-[45px] xl:pl-12 xl:pr-24 py-2 md:py-4 text-white text-[18px] leading-6 font-bold rounded-r"
                  >
                    PRIZE
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-motion-slide-visible-bottom
                  v-for="(item, index) in topRest"
                  :key="index"
                  style="font-family: Lato, sans-serif"
                  class="rounded bg-transparent"
                >
                  <td
                    class="px-2 md:px-5 lg:px-[45px] xl:px-12 py-2 md:py-4 whitespace-nowrap text-base font-normal text-right uppercase"
                    style="font-family: Russo One, system-ui"
                  >
                    <div class="text-white tracking-[4px]">
                      {{ getOrdinalSuffix(index + 4) }}
                    </div>
                  </td>
                  <td
                    class="px-2 md:px-5 max-w-[76px] overflow-hidden text-ellipsis md:max-w-full lg:px-[45px] xl:px-28 py-2 md:py-5 whitespace-nowrap text-base text-white font-normal text-center tracking-[4px]"
                  >
                    {{ maskString(item.user_name) }}
                  </td>
                  <td
                    class="px-1 md:px-5 lg:px-[45px] xl:px-28 py-2 md:py-5 whitespace-nowrap text-base text-white font-normal text-center tracking-[4px]"
                  >
                    {{ item.wagered }}
                  </td>
                  <td
                    class="px-1 text-left md:px-5 lg:px-[45px] xl:px-12 py-2 md:py-5 whitespace-nowrap text-base text-white font-black tracking-[4px]"
                  >
                    <span class="text-inherit text-[#F42F09]">{{ getRewardValue(index + 3)[0] }}</span>
                    {{ getRewardValue(index + 3).substring(1) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- <PrevLeaderboardSection ref="PrevLeaderboardSection"/> -->
</template>

<script>
// import axios from 'axios';
import axios from "axios";
import { PRIZES } from "../../const";
import moment from "moment-timezone";
// import { watch } from 'vue';
// import PrevLeaderboardSection from './PrevLeaderboardSection.vue';

export default {
  components: {
    // PrevLeaderboardSection
  },
  data() {
    return {
      top3: [],
      topRest: [],
      topForMobile: [],
      timer: "",
      selectedLeaderboard: false,

      currentLeaderboard: [],
      prevLeaderboard: [],
      showPrevLeaderboard: "false",

      startDate: null,
      endDate: null,

      notFormattedEndDate: null,

      loading: false,
    };
  },
  methods: {
    async init() {
      this.showPrevLeaderboard = process.env.VUE_APP_SHOW_PREVIOUS_LEADERBOARD;
      const baseUrl = process.env.VUE_APP_BASE_API;
      this.loading = true;
      await axios
        .get(`${baseUrl}/api/leaderboard/stake`, {
          headers: {
            "x-api-key": process.env.VUE_APP_X_API_KEY,
          },
        })
        .then((res) => {
          this.startDate = moment(res.data.startDate).format("MMMM DD, YYYY");
          this.endDate = moment(res.data.endDate).format("MMMM DD, YYYY");
          this.notFormattedEndDate = res.data.endDate;
          this.currentLeaderboard = res.data.leaderboard;
          this.changeLeaderboard();
          this.startTimer();
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    changeLeaderboard() {
      if (this.selectedLeaderboard == false) {
        const leaderboard = this.currentLeaderboard;
        this.top3 = leaderboard.slice(0, 3).map((item) => {
          return item;
        });

        this.topRest = leaderboard.slice(3, 40).map((item) => {
          return item;
        });

        this.topForMobile = leaderboard.slice(0, 40).map((item) => {
          return item;
        });
      } else {
        // const leaderboard = this.prevLeaderboard;
        // this.top3 = leaderboard.slice(0, 3).map((item) => {
        //   return item;
        // });
        // this.topRest = leaderboard.slice(3, 40).map((item) => {
        //   return item;
        // });
        // this.topForMobile = leaderboard.slice(0, 40).map((item) => {
        //   return item;
        // });
      }
    },
    maskString(str) {
      if (!str || str.length <= 4) return str;

      const start = str.slice(0, -3);
      const endAsterisks = "*".repeat(3);

      return `${start}${endAsterisks}`;
    },
    getOrdinalSuffix(n) {
      const s = ["th", "st", "nd", "rd"],
        v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    getRewardValue(rank) {
      // if (rank >= 10 && rank < 15) {
      //   return '$'+ 20;
      // }
      // else if(rank >= 15){
      //   return '---'
      // }
      return "$" + PRIZES[rank];
    },
    startTimer() {
      const getNextTargetDate = () => {
        const now = moment().utc();
        let targetDate = moment(`${this.notFormattedEndDate}T00:00:00Z`)
          .utc()
          .subtract(1, "seconds");

        if (now.isAfter(targetDate)) {
          targetDate.add(1, "week");
        }

        return targetDate;
      };

      let targetDate = getNextTargetDate();
      let timerInterval;

      const updateTimer = () => {
        const nowEst = moment.tz("America/New_York");
        const distance = targetDate.diff(nowEst);
        if (distance >= 0) {
          const duration = moment.duration(distance);
          const days = Math.floor(duration.asDays());
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();

          this.timer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        } else {
          this.timer = "Leaderboard ended";
          clearInterval(timerInterval);
        }
      };

      updateTimer();
      timerInterval = setInterval(updateTimer, 1000);
    },
    isMobile() {
      return window?.innerWidth < 768; // Adjust based on your 'lg' breakpoint in Tailwind CSS config
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped></style>
