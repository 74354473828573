<template>
  <section
    id="level-up-rewards"
    class="flex flex-col justify-center items-center pt-40 md:pt-[231px] md:pb-[67px]"
  >
    <div class="p-2 md:py-8 md:px-4">
      <div
        class="text-white text-2xl md:text-[48px] text-center leading-[48px]"
        style="font-family: Montserrat, sans-serif"
      >
        DUSTIN'S
        <span
          class="text-transparent bg-clip-text bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] font-[900]"
        >
          LEVEL UP REWARDS
        </span>
      </div>
    </div>
    <div
      class="flex flex-wrap flex-row justify-center gap-6 md:gap-[42px] mt-10 md:mt-[84px] px-4 xl:px-0 max-w-[1270px] mx-auto"
    >
      <div class="max-h-[220px] max-w-[220px]" v-motion-pop-visible>
        <img src="../assets/reward-1.png" class="w-full h-full object-cover" />
      </div>
      <div class="max-h-[220px] max-w-[220px]" v-motion-pop-visible>
        <img src="../assets/reward-2.png" class="w-full h-full object-cover" />
      </div>
      <div class="max-h-[220px] max-w-[220px]" v-motion-pop-visible>
        <img src="../assets/reward-3.png" class="w-full h-full object-cover" />
      </div>
      <div class="max-h-[220px] max-w-[220px]" v-motion-pop-visible>
        <img src="../assets/reward-4.png" class="w-full h-full object-cover" />
      </div>
      <div class="max-h-[220px] max-w-[220px]" v-motion-pop-visible>
        <img src="../assets/reward-5.png" class="w-full h-full object-cover" />
      </div>
      <div class="max-h-[220px] max-w-[220px]" v-motion-pop-visible>
        <img src="../assets/reward-6.png" class="w-full h-full object-cover" />
      </div>
      <div class="max-h-[220px] max-w-[220px]" v-motion-pop-visible>
        <img src="../assets/reward-7.png" class="w-full h-full object-cover" />
      </div>
      <div class="max-h-[220px] max-w-[220px]" v-motion-pop-visible>
        <img src="../assets/reward-8.png" class="w-full h-full object-cover" />
      </div>
      <div class="max-h-[220px] max-w-[220px]" v-motion-pop-visible>
        <img src="../assets/reward-9.png" class="w-full h-full object-cover" />
      </div>
      <div class="max-h-[220px] max-w-[220px]" v-motion-pop-visible>
        <img src="../assets/reward-10.png" class="w-full h-full object-cover" />
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped></style>
