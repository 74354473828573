<template>
  <!-- Main section start -->
  <div class="bg-[#090F29] relative">
    <img
      src="../assets/Overlays.png"
      class="absolute inset-0 object-cover object-center bg-repeat-y w-full h-full"
    />
    <section class="min-h-screen main-bg" id="main">
      <div
        class="flex flex-col pt-20 md:pt-40 p-8 lg:pt-24 2xl:pt-44 lg:px-16 2xl:px-56 z-30 gap-4 justify-center items-center"
      >
        <div class="flex justify-center mt-[76px] sm:mt-[100px]">
          <img
            v-motion-pop-visible
            src="../assets/Dustin-hero.png"
            class="max-h-[130px] w-auto"
            alt="title-text"
          />
        </div>
        <div
          class="text-white font-['Lato'] font-medium text-xl sm:text-[36px] mt-10"
        >
          WITH
        </div>
        <div class="flex justify-center mt-0">
          <img
            v-motion-pop-visible
            src="../assets/stake-hero.png"
            class="max-h-[100px] w-auto sm:max-h-[206px]"
            alt="title-text"
          />
        </div>
        <div
          v-motion-pop-visible
          class="flex flex-nowrap sm:flex-row flex-col justify-center items-center gap-4 mt-5 md:mt-6 lg:mt-6 z-30"
        >
          <a
            href="https://kick.com/dustin"
            class="skew-x-[-20deg] text-white font-bold border-none w-full px-8 py-[26px] sm:py-6 xl:px-[70px] shadow-2xl bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] hover:border-none"
          >
            <p
              class="skew-x-[20deg] text-white font-extrabold sm:text-lg text-sm text-center whitespace-nowrap"
              style="font-family: Lato, sans-serif"
            >
              WATCH ME LIVE ON KICK
            </p>
          </a>
          <a
            href="https://stake.com/?c=DustinTFP"
            class="skew-x-[-20deg] bg-transparent relative w-full px-9 py-[26px] sm:py-6 xl:px-[78px] border border-solid"
            style="border-image: linear-gradient( to right, #f42f09 0%, #e69b6a 50%, #ff812e 100%) 1;"
          >
            <p
              class="bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-transparent whitespace-nowrap bg-clip-text text-center text-sm skew-x-[20deg] text-white font-extrabold sm:text-lg"
              style="font-family: Lato, sans-serif"
            >
              REGISTER ON STAKE.COM
            </p>
          </a>
        </div>
      </div>
      <div class="flex flex-col gap-[115px] mt-[74px] lg:mt-[150px]">
        <img
          src="../assets/slash.png"
          class="object-center object-cover hidden sm:block w-full"
        />
        <img
          src="../assets/slash-sp.png"
          class="object-center object-cover block sm:hidden"
        />
        <div class="px-6 lg:px-0">
          <div class="flex items-center relative xl:px-20">
            <div
              id="promo-card"
              class="flex justify-between gap-y-10 flex-col lg:flex-row items-center h-fit lg:h-[424px] w-full relative rounded-[20px] px-4 xl:px-[50px] mx-auto py-[37px] max-w-[1297px]"
            >
              <img
                src="../assets/Ellipse4.png"
                class="absolute right-0 h-[424px] w-auto lg:w-[55%] xl:w-auto rounded-r-[20px] z-10 hidden lg:block"
              />
              <img
                src="../assets/Ellipse-4-sp.png"
                class="absolute bottom-0 w-auto max-h-[595px] rounded-bl-[20px] sm:w-full sm:h-auto rounded-r-[20px] z-10 block lg:hidden"
              />
              <div
                class="flex justify-center items-center flex-col gap-4 z-20 font-['Lato']"
              >
                <img
                  src="../assets/stake-hero.png"
                  class="max-h-[146px] w-auto"
                />
                <h3 class="mt-[34px] text-white text-center text-base">
                  REGISTER NOW TO GET EXCITING REWARDS EVERYDAY!
                </h3>
                <div class="flex"></div>
                <h3 class="text-white text-center">
                  USE CODE:
                  <span
                    class="text-transparent bg-clip-text bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E]"
                    >DUSTINTFP</span
                  >
                </h3>
                <a
                  href="https://stake.com/?c=DustinTFP"
                  class="skew-x-[-20deg] text-white w-fit text-center font-bold border-none py-6 px-10 lg:px-16 shadow-2xl bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] hover:border-none"
                >
                  <p
                    class="skew-x-[20deg] text-white font-extrabold text-base md:text-lg w-fit"
                    style="font-family: Lato, sans-serif"
                  >
                    REGISTER ON STAKE.COM
                  </p>
                </a>
              </div>
              <div class="flex flex-col-reverse lg:flex-row gap-4 z-10">
                <img src="../assets/image-2.png" class="rounded-[20px]" />
                <img src="../assets/image-3.png" class="rounded-[20px]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="h-auto">
      <LevelUpReward />
      <LeaderboardSection />
      <SocialSection />
      <PromotionSection />
    </section>
  </div>
</template>

<script>
import LeaderboardSection from "@/sections/LeaderboardSection.vue";
import LevelUpReward from "@/sections/LevelUpReward.vue";
import SocialSection from "@/sections/SocialSection.vue";
import PromotionSection from "@/sections/PromotionSection.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    LeaderboardSection,
    LevelUpReward,
    PromotionSection,
    SocialSection,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {},
});
</script>

<style scope>
body {
  background-color: black;
}

#promo-card {
  background-image: url("../assets/promo-card.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
