<template>
  <section
    id="socials"
    class="flex flex-col justify-center items-center pt-12 md:pt-16 lg:pt-20"
  >
    <div class="p-2 md:py-8 md:px-4">
      <div
        class="text-white text-2xl md:text-[48px] text-center leading-[48px]"
        style="font-family: Montserrat, sans-serif"
      >
        FOLLOW ME ON MY
        <p
          class="text-transparent bg-clip-text bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] font-[900]"
        >
          SOCIALS
        </p>
      </div>
    </div>
    <div
      class="flex flex-wrap flex-row justify-center gap-6 md:gap-[74px] mt-6 md:mt-[84px]"
    >
      <div
        v-motion-slide-visible-bottom
        class="flex flex-col justify-center items-center bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B] border border-[#FFFFFF33] h-[150px] w-[150px]"
      >
        <a href="https://x.com/dustintfp" target="_blank">
          <img src="../assets/X.png" class="h-12 w-12" alt="X" />
        </a>
      </div>
      <div
        v-motion-slide-visible-bottom
        class="flex flex-col flex-wrap justify-center items-center bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B] border border-[#FFFFFF33] h-[150px] w-[150px]"
      >
        <a href="https://www.instagram.com/dustintfp" target="_blank">
          <img
            src="../assets/instagram.png"
            class="h-12 w-12"
            alt="instagram"
          />
        </a>
      </div>
      <div
        v-motion-slide-visible-bottom
        class="flex flex-col flex-wrap justify-center items-center bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B] border border-[#FFFFFF33] h-[150px] w-[150px]"
      >
        <a href="https://www.youtube.com/@DustinTFP" target="_blank">
          <img src="../assets/youtube.png" class="h-12 w-12" alt="youtube" />
        </a>
      </div>
      <div
        v-motion-slide-visible-bottom
        class="flex flex-col flex-wrap justify-center items-center bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B] border border-[#FFFFFF33] h-[150px] w-[150px]"
      >
        <a href="https://kick.com/dustin" target="_blank">
          <img src="../assets/kick.png" class="h-12 w-12" alt="kick" />
        </a>
      </div>
      <div
        v-motion-slide-visible-bottom
        class="flex flex-col flex-wrap justify-center items-center bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B] border border-[#FFFFFF33] h-[150px] w-[150px]"
      >
        <a href="https://discord.gg/vds7juyjF8" target="_blank">
          <img src="../assets/discord.png" class="h-12 w-12 object-contain" alt="discord" />
        </a>
      </div>
      <div
        v-motion-slide-visible-bottom
        class="flex flex-col flex-wrap justify-center items-center bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B] border border-[#FFFFFF33] h-[150px] w-[150px]"
      >
        <a href="https://www.tiktok.com/@dustintfp" target="_blank">
          <img src="../assets/tiktok.png" class="h-12 w-12" alt="tiktok" />
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped></style>
